import * as React from 'react';
import clsx from 'clsx';
import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core';
import useStyles from './styles';

export default function HowItWorks() {
  const classes = useStyles();

  return (
    <Box className={classes.aboutUs}>
      <Container className={classes.container}>
        <Grid container className={classes.relative}>
          <Grid item xs={12} sm={5}>
            <Typography variant="h3" className={classes.title}>
              How it works
            </Typography>
            <Hidden xsDown>
              <img
                src="https://res.cloudinary.com/rmnfrk9/image/upload/w_595,q_70/static/image_home_1_dggcg7.jpg"
                alt="A flat's interior"
              />
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={7} className={classes.info}>
            <Box className={classes.padding}>
              <Box className={clsx(classes.des, classes.landlords)}>
                <Typography variant="h6">Open-source database</Typography>
                <Typography>
                  We list the largest Build To Rent landlords and property
                  managers in the UK and globally, managing over 100,000
                  properties at 600 developments. Our data was collected from
                  various public sources, then cleaned and processed to give
                  users a standard overview of each development amid the whole
                  sector.
                </Typography>
              </Box>
              <Hidden smUp>
                <Grid>
                  <img
                    src="https://res.cloudinary.com/rmnfrk9/image/upload/w_595,q_70/static/image_home_1_dggcg7.jpg"
                    alt="A flat's interior"
                  />
                </Grid>
              </Hidden>
              <Box className={clsx(classes.des, classes.quality)}>
                <Typography variant="h6">
                  Best rental quality for tenants
                </Typography>
                <Typography>
                  Our listed properties are new builds with perfect locations,
                  modern designs and high quality. Tenants can research any
                  development in depth and benchmark against others in the same
                  area or budget.
                </Typography>
                <Typography>
                  No middleman is involved in your search. You will deal with
                  the direct managers and landlords from day one.
                </Typography>
              </Box>
              <Box className={clsx(classes.des, classes.quality)}>
                <Typography variant="h6">
                  Specialist platform for Build to Rent and co-living managers
                </Typography>
                <Typography>
                  The most common issue in the Build To Rent sector is that
                  prospective tenants are not aware of this sector and the
                  potential benefits it can bring. By listing on our platform
                  together with like-minded businesses, landlords will receive
                  new leads and customer analytics to improve your
                  developments&#39; performances and tailor your next project to
                  best fit upcoming market trends.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
