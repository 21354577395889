import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    searchBarCtn: {
      paddingBottom: theme.spacing(2),
      display: 'flex',
      '& > div': {
        maxWidth: 'calc(100% - 138px)',
        flex: '0 0 calc(100% - 138px)',
        [theme.breakpoints.down('xs')]: {
          maxWidth: 'calc(100% - 78px)',
          flex: '0 0 calc(100% - 78px)',
        },
        '& div': {
          borderRadius: 0,
        },
      },
    },
    customPaddingBox: {
      maxWidth: 960,
      width: '100%',
      boxShadow: 'none',
      background: 'transparent',
      padding: 0,
      border: 0,
      '& div': {
        padding: '0',
      },
    },

    searchBtnCtn: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    btnSearch: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      textTransform: 'initial',
      fontSize: '1.125rem',
      paddingLeft: theme.spacing(5.2),
      paddingRight: theme.spacing(5.2),
      borderRadius: 0,
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        width: 78,
      },
      '&:hover': {
        backgroundColor: 'red',
      },
    },
    inputSearch: {
      '& fieldset': {
        borderColor: `${theme.palette.primary.main}`,
        borderWidth: 2,
      },
      '& .MuiOutlinedInput-root:hover fieldset': {
        borderColor: `${theme.palette.primary.main}`,
      },
      backgroundColor: '#fff',
    },
  })
);
