import * as React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import ConnectedAddressAutocomplete, {
  ConnectedAddressAutocompleteProps,
} from '../../../../widgets/ConnectedAddressAutocomplete/ConnectedAddressAutocomplete';

// ========== TYPES ========== //

export type SearchBarProps = ConnectedAddressAutocompleteProps;

// ========== STYLES ========== //

const useStyles = makeStyles(() =>
  createStyles({
    ctn: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

// ========== COMPONENT ========== //

export default function SearchBar({
  ...addressAutocompleteInputProps
}: SearchBarProps) {
  const classes = useStyles();

  return (
    <Box className={classes.ctn}>
      <ConnectedAddressAutocomplete {...addressAutocompleteInputProps} />
    </Box>
  );
}
