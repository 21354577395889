import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    grey: {
      backgroundColor: '#F3F3F3',
    },
    title: {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: theme.palette.gray.contrastText,
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.5rem',
      },
    },
  })
);
