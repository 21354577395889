import * as React from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

export default function AboutUs() {
  const classes = useStyles();

  return (
    <Box className={classes.aboutUs}>
      <Container className={classes.container}>
        <Grid container className={classes.relative}>
          <Grid item xs={12} sm={5}>
            <img
              src="https://res.cloudinary.com/rmnfrk9/image/upload/w_595,q_70/static/image_home_diugar.jpg"
              alt="A flat's interior"
            />
          </Grid>
          <Grid item xs={12} sm={7} className={classes.info}>
            <Box className={classes.pl60}>
              <Typography variant="h3" className={classes.title}>
                About Us
              </Typography>
              <Typography variant="h5" className={classes.text}>
                We are a community edited database of Build to Rent and
                co-living developments in the UK.
              </Typography>
              <Box className={classes.des}>
                <Typography>
                  We do not sell or let property, instead we aggregate public
                  data of the developments and list their managers along with
                  links to their websites and direct contact details. Our
                  content-rich, data-driven platform helps tenants research
                  their next rental homes in depth and connect with landlords
                  directly. Customer feedbacks create invaluable insights for
                  prospective tenants and allow landlords to tailor their future
                  developments to best fit upcoming market trends.
                </Typography>
                <Typography>
                  For any enquiries please contact us:{' '}
                  <a href="mailto:info@buildtorent.io.">info@buildtorent.io</a>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
