import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    aboutUs: {
      padding: '130px 15px 100px',
      backgroundImage: 'linear-gradient(to bottom, #F3F3F3 74%,#FFFFFF 74%)',
      [theme.breakpoints.down('sm')]: {
        padding: '60px 15px',
      },
    },
    container: {
      maxWidth: theme.custom.container1460,
      margin: 'auto',
      padding: 0,
    },
    relative: {
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
    info: {
      paddingLeft: 60,
      '&:before': {
        content: '""',
        position: 'absolute',
        height: '50%',
        width: 1.5,
        backgroundColor: theme.palette.primary.main,
        bottom: 5,
        [theme.breakpoints.down('xs')]: {
          height: 0,
        },
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 30,
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        marginBottom: 30,
      },
    },
    pl60: {
      paddingLeft: 60,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 30,
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
      },
    },
    title: {
      fontSize: '3.125rem',
      fontWeight: 'bold',
      color: theme.palette.gray.contrastText,
      textTransform: 'uppercase',
      marginTop: 15,
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.25rem',
        marginTop: 0,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.5rem',
        marginBottom: 20,
      },
    },
    text: {
      fontSize: '1.4375rem',
      fontWeight: 'bold',
      color: theme.palette.gray.contrastText,
      textTransform: 'uppercase',
      margin: '5px 0 25px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
    },
    des: {
      color: theme.palette.gray.dark,
      '& h6': {
        fontSize: '1.5625rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.125rem',
        },
      },
      '& p': {
        fontSize: '1.375rem',
        marginTop: 25,
        [theme.breakpoints.down('sm')]: {
          fontSize: '1rem',
        },
      },
    },
  })
);
