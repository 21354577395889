import { useState } from 'react';
import {
  PlaceResult,
  AutocompletePrediction,
} from '../../api/googleMaps/gcpPlaces/types';

/**
 * This hook links closely with the AddressAutocompleteInput component
 */
export default function useAddressAutocompleteInput() {
  const [inputValue, setInputValue] = useState('');
  const [autocompleteValue, setAutocompleteValue] =
    useState<AutocompletePrediction | null>(null);
  const [predictions, setPredictions] = useState<
    AutocompletePrediction[] | null
  >(null);
  const [placeDetails, setPlaceDetails] = useState<PlaceResult | null>(null);

  return {
    inputValue,
    setInputValue,
    autocompleteValue,
    setAutocompleteValue,
    predictions,
    setPredictions,
    placeDetails,
    setPlaceDetails,
  };
}
