import * as React from 'react';
import { Box } from '@material-ui/core';
import SEO from '../components/atoms/SEO';
import GenericPage from '../components/layout/CommonLayout/GenericPage';
import VideoIntroduction from '../components/pages/about/HowItWorks/VideoIntroduction';
import AboutUs from '../components/pages/about/HowItWorks/AboutUs';
import HowItWorks from '../components/pages/about/HowItWorks/HowItWorks';
import FindYourIdealHome from '../components/pages/about/HowItWorks/FindYourIdealHome';
import useAnalyticsSessionData from '../hooks/useAnalyticsSessionData';

export default function About() {
  useAnalyticsSessionData();

  return (
    <GenericPage>
      <SEO title="About" />
      <Box>
        <VideoIntroduction />
        <AboutUs />
        <HowItWorks />
        <FindYourIdealHome />
      </Box>
    </GenericPage>
  );
}
