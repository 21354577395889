import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import FindYourIdealHomeSearchCard from './FindYourIdealHomeSearchCard';
import useStyles from './styles';

function FindYourIdealHome(props) {
  const classes = useStyles();

  return (
    <Box
      p="60px 15px"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={classes.grey}
    >
      <Box pb={3}>
        <Typography variant="h4" className={classes.title}>
          Find your ideal home
        </Typography>
      </Box>
      <FindYourIdealHomeSearchCard />
    </Box>
  );
}

export default FindYourIdealHome;
