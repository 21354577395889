import { Box, Container, Grid, Typography } from '@material-ui/core';
import * as React from 'react';
import PopupVideoIntroduction from './PopupVideoIntroduction';
import useStyles from './styles';

function VideoIntroduction(props) {
  const classes = useStyles();
  return (
    <Box className={classes.videoIntro}>
      <Container className={classes.container}>
        <Grid container className={classes.row}>
          <Grid item className={classes.text}>
            <Typography variant="h3" className={classes.title}>
              VIDEO INTRODUCTION
            </Typography>
          </Grid>
          <Grid item className={classes.video}>
            <PopupVideoIntroduction />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default VideoIntroduction;
