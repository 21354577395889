import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    relative: {
      position: 'relative',
      cursor: 'pointer',
    },
    psrelative: {
      position: 'relative',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 5,
      right: 0,
      left: 0,
      background: 'rgba(0,0,0,0.16)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    animationIcon: {
      width: 156,
      height: 156,
      background: 'rgba(255,255,255,0.5)',
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      animation: '$pulse 1s ease-out infinite',
      [theme.breakpoints.down('xs')]: {
        width: 100,
        height: 100,
      },
    },
    icon: {
      color: '#ffffff',
      fontSize: '7.75rem',
      position: 'absolute',
      [theme.breakpoints.down('xs')]: {
        fontSize: 60,
      },
    },
    '@keyframes pulse': {
      '0%': {
        opacity: 1,
        transform: 'scale(1)',
      },
      '100%': {
        opacity: 0,
        transform: 'scale(1.2)',
      },
    },
    rectangle: {
      maxWidth: 1060,
      height: 510,
      background: '#F9B7A9',
      position: 'absolute',
      right: -30,
      bottom: -30,
      width: '100%',
      [theme.breakpoints.down(1550)]: {
        right: -15,
      },
      [theme.breakpoints.down('sm')]: {
        height: '100%',
      },
    },
    video: {
      position: 'relative',
      paddingBottom: '56.25%',
      height: 0,
      '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
    },
  })
);
