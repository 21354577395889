import * as React from 'react';
import { Box } from '@material-ui/core';
import useStyles from './styles/PopupVideoIntroduction';

function PopupVideoIntroduction() {
  const classes = useStyles();

  return (
    <Box className={classes.psrelative}>
      <Box className={classes.rectangle} />
      <div className={classes.video}>
        <iframe
          className="yt-video"
          width="100%"
          src={`https://www.youtube.com/embed/${'eCXardtop7c'}`}
          srcDoc={`
        <style>
          *{padding:0;margin:0;overflow:hidden;box-sizing: border-box;}
          html,body{height:100%}
          img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto;display:flex;justify-content:center;}
          span{height:1.5em;text-align:center;font:96px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}
          img{filter:brightness(0.75);}
          .ytv-play-btn-ctn{display:inline-block;width:144px;padding:24px;}
          .ytv-play-btn{background-color:rgba(33,33,33,0.5);border-radius:50%;padding:16px;}
        </style>
        <a href=https://www.youtube.com/embed/${'eCXardtop7c'}?autoplay=1>
        <img src=https://img.youtube.com/vi/${'eCXardtop7c'}/maxresdefault.jpg alt=${
            'title' ?? 'Video'
          }>
        <span>
          <div class="ytv-play-btn-ctn">
            <svg class="ytv-play-btn"
              role="img"
              viewBox="0 0 24 24" width="100%"
              height="100%"
              fill="#ffffff">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M8 5v14l11-7z" />
            </svg>
          </div>
        </span>
        </a>`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={'title'}
        />
      </div>
    </Box>
  );
}

export default PopupVideoIntroduction;
