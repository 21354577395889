import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    videoIntro: {
      padding: '96px 15px',
      [theme.breakpoints.down('sm')]: {
        padding: '70px 15px',
      },
    },
    container: {
      maxWidth: theme.custom.container1460,
      margin: 'auto',
      padding: 0,
    },
    row: {
      margin: 0,
    },
    text: {
      flex: '0 0 270px',
      maxWidth: 270,
      [theme.breakpoints.down('md')]: {
        flex: '0 0 200px',
        maxWidth: 200,
      },
      [theme.breakpoints.down(767)]: {
        flex: '0 0 100%',
        maxWidth: '100%',
      },
    },
    video: {
      flex: '0 0 calc(100% - 270px)',
      maxWidth: 'calc(100% - 270px)',
      [theme.breakpoints.down('md')]: {
        flex: '0 0 calc(100% - 200px)',
        maxWidth: 'calc(100% - 200px)',
      },
      [theme.breakpoints.down(767)]: {
        flex: '0 0 100%',
        maxWidth: '100%',
        marginTop: 30,
      },
    },
    title: {
      fontSize: '1.875rem',
      fontWeight: 'bold',
      color: theme.palette.gray.contrastText,
      paddingLeft: 13,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      lineHeight: 1,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },
  })
);
