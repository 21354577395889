import React from 'react';
import { Box, Button, Card, CardContent } from '@material-ui/core';
import { navigate } from '../../../../../utils/dom';
import useAddressAutocompleteInput from '../../../../../hooks/useAddressAutocompleteInput/useAddressAutocompleteInput';
import SearchBar from './SearchBar';
import { combineQueryParams } from '../../../../../utils/url';
import useStyles from './styles/FormFindYour';
import useAutocompleteServices from '../../../../../api/googleMaps/gcpPlaces/hooks/useAutocompleteServices';

// ========== COMPONENT ========== //

const DISTANCE_FROM_LOCATION_CAP = 64; // km

const bedroomTypes = {
  Any: -1,
  Studio: 0,
  '1 Bedroom': 1,
  '2 Bedroom': 2,
  '3 Bedroom': 3,
  '4 Bedroom': 4,
};

export default function FindYourIdealHomeSearchCard() {
  const classes = useStyles();

  const { autocompleteService, placesService } = useAutocompleteServices({
    googleMapsAttributeElementId: 'google-maps-att-el',
  });

  const addressAutocompleteInput = useAddressAutocompleteInput();

  const filters = {
    radius: -1,
    bedroomType: bedroomTypes.Any,
    maxRent: 0,
  };

  // ---------- 🔍 SEARCH 🔍 ---------- //

  // This actually redirects the user to the '/properties' page with the search
  // text as a path parameter. This is NOT where the actual search API call
  // happens.
  const initiateSearch = () => {
    const urlComponents = {
      address: addressAutocompleteInput.inputValue,
      radius:
        filters.radius > 0
          ? Math.min(filters.radius, DISTANCE_FROM_LOCATION_CAP) * 1000
          : 5000,
      // maxRentPcm: filters.maxRent,
      ['flats.bedrooms_count']:
        filters.bedroomType === -1 ? undefined : filters.bedroomType,
    };

    // a.k.a. "search"
    const urlQueryString = combineQueryParams(urlComponents);

    void navigate(`/properties/?${urlQueryString}`);
  };

  // ---------- Render ---------- //

  return (
    <Card className={classes.customPaddingBox}>
      {/* <CardHeader title="Search build to rent properties in the UK" /> */}
      <CardContent>
        <Box className={classes.searchBarCtn}>
          <SearchBar
            className={classes.inputSearch}
            variant="outlined"
            autocompleteService={autocompleteService}
            placesService={placesService}
            {...addressAutocompleteInput}
          />
          <Button
            variant="contained"
            className={classes.btnSearch}
            onClick={initiateSearch}
          >
            Search
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
